/**
 *  Property of OSF GLOBAL SERVICES INC. , an OSF Digital company. OSF remains the sole owner of all right, title and interest in the software. Do not copy, sell, reverse engineer or otherwise attempt to derive or obtain information about the functioning, manufacture or operation therein.
 */

"use strict";

var unescape = require("./utils/unescape");
let liveClick = [];

var impressionCounter = 0;
class CBTController {
    /**
     * function to push data to the dataLayer, and add hash
     * @param {Object} data - data to push
     */
    static hashPush(data) {
        var dataReassigned = data;
        if (dataReassigned) {
            if (dataReassigned.options !== "Quantity" || dataReassigned.options !== "Options") {
                delete dataReassigned.options;
            }
            if (dataReassigned.eventTypes) {
                delete dataReassigned.eventTypes;
            }
            if (dataReassigned.selector) {
                delete dataReassigned.selector;
            }
            // eslint-disable-next-line no-restricted-globals
            if ((location.hash.indexOf(dataReassigned.event + "cbt") === -1)) {
                dataLayer.push(dataReassigned);
            }
        }
    }

    /**
     * Parse the data object into json
     * @param {string} data
     * @returns {Object} jsonObject
     */

    static parseJSON(data) {
        var dataReassigned = data;
        let parsedJson = {};
        try {
            parsedJson = JSON.parse(dataReassigned);
        } catch (error) {
            // Handle cases of missing closing quote
            if (dataReassigned.length > dataReassigned.lastIndexOf("}")) {
                dataReassigned = dataReassigned.substring(0, dataReassigned.lastIndexOf("}") + 1);
            }
            // eslint-disable-next-line quotes
            parsedJson = JSON.parse(dataReassigned.replace(/'/gi, '"').replace(/\\"/, "'"));
        }
        return parsedJson;
    }

    /**
     * check data type and parse string into JSON
     * @param {Object} data - data to push
     */
    static dataPush(data) {
        var dataReassigned = data;
        if ((typeof (dataReassigned) === "string") && (dataReassigned !== "")) {
            if ((dataReassigned != null) || (dataReassigned !== "null")) {
                dataReassigned = CBTController.parseJSON(unescape(dataReassigned));
                CBTController.hashPush(dataReassigned);
            }
        } else {
            CBTController.hashPush(data);
        }
    }

    static tileViewObserverCallback(entries, observer) {
        var productItems = [];
        entries.forEach(entry => {
            if (entry.isIntersecting && window.impressionsContainer) {
                observer.unobserve(entry.target);
                var productID = entry.target.getAttribute("data-itemid");
                window.impressionsContainer = window.impressionsContainer.filter((impression) => {
                    var result = true;
                    if (impression.item_id === productID || impression.item_variant.includes(productID)) {
                        impression.item_variant = impression.item_id;
                        productItems.push(impression);
                        result = false;
                    }
                    return result;
                });
            }
        });

        let dataLayerObj = {
            event: "view_item_list",
            ecommerce: {}
        };
        dataLayerObj.ecommerce.items = productItems;

        if (dataLayerObj.ecommerce.items.length > 0) {
            CBTController.dataPush(dataLayerObj);
        } else {
            dataLayerObj = "";
        }
    }

    /**
    * Finds all data DOM elements and pushes them to the data layer
    * @param {HTMLElement} node - the node containing the data-cbt attribute
    * @param {boolean} initialLoad - whether this is the initial page load
    */
    static findAllDataDOM(node, initialLoad) {
        if (!window.impressionsContainer || initialLoad) {
            window.impressionsContainer = [];
        }
        var dataCollectionObj = {};
        window.yValuesarray = [];

        /**
        * Push data layer objects to the data layer
        * @param {HTMLElement} node - the node containing the data-cbt attribute
        * @returns {Array} impressionsContainer - an array of items
        */
        function dataPushDataLayerObj() {
            let allCBTData = node.querySelectorAll("[data-cbt]");

            if (!allCBTData.length) {
                return window.impressionsContainer;
            }

            for (let i = 0; i < allCBTData.length; i += 1) {
                let tempNode = allCBTData[i];
                dataCollectionObj = CBTController.parseJSON(tempNode.getAttribute("data-cbt"));

                if (dataCollectionObj && dataCollectionObj.event === "view_item_list") {
                    dataCollectionObj.ecommerce.items.index = impressionCounter;
                    impressionCounter += 1;
                }

                // Handle Search Bar input
                if (dataCollectionObj && dataCollectionObj.action === "Search" && dataCollectionObj.label) {
                    CBTController.dataPush(dataCollectionObj);
                }
                if (!dataCollectionObj || !dataCollectionObj.eventTypes || !dataCollectionObj.event) {
                    continue;
                }

                let eventTypes = dataCollectionObj.eventTypes.split(",");

                if (eventTypes.indexOf("show") !== -1) {
                    // Handle show events that appered as a result of a click in specific cases
                    if (dataCollectionObj.eventTypes.indexOf("click") !== -1) {
                        dataCollectionObj.category = "Click";
                        CBTController.dataPush(dataCollectionObj);
                        continue;
                    }
                    if (dataCollectionObj.event === "view_item_list") {
                        impressionsContainer.push(dataCollectionObj.ecommerce.items); // eslint-disable-line
                    } else {
                        CBTController.dataPush(dataCollectionObj);
                    }
                    tempNode.removeAttribute("data-cbt");
                }
            }
            return impressionsContainer; // eslint-disable-line
        }

        window.impressionsContainer = dataPushDataLayerObj();

        if (window.impressionsContainer.length > 0) {
            var products = document.querySelectorAll(".viewItemListTrigger");

            const observer = new IntersectionObserver(CBTController.tileViewObserverCallback,
                {
                    threshold: 1
                });

            var moreResultButton = document.querySelector(".show-more");

            if (!moreResultButton) {
                window.gridload = true;
            }

            if (initialLoad || window.gridload) {
                window.gridload = false;
                products.forEach(product => {
                    observer.observe(product);
                });
            }

            window.totalGrid = products;

            $(".container").on("click", ".show-more button", function (e) {
                e.preventDefault();
                var loaded = false;
                $(document).ajaxComplete((event, xhr, settings) => {
                    if (settings.url.includes("Search-UpdateGrid") && !loaded) {
                        loaded = true;
                        var firstGrid = document.querySelectorAll(".viewItemListTrigger");
                        var otherGrid = [];
                        firstGrid.forEach((element, index) => {
                            if (index >= window.totalGrid.length) {
                                otherGrid.push(element);
                            }
                        });

                        if (otherGrid.length > 12) {
                            otherGrid = "";
                        }

                        window.totalGrid = [...window.totalGrid, ...otherGrid];
                        products = [...otherGrid];

                        // eslint-disable-next-line
                        const observer = new IntersectionObserver(CBTController.tileViewObserverCallback,
                            {
                                threshold: 1
                            });
                        setTimeout(() => {
                            products.forEach(product => {
                                observer.observe(product);
                            });
                        }, 100);
                    }
                });
            });
        }
    }

    /* Update Product Tile data-cbt attribute with actual index so it works with "Show more" feature */
    static updateTilesPosition(nodes) {
        var productCollection = [];
        var productCounter = -1;

        for (let i = 0; i < nodes.length; i += 1) {
            let tempNode = nodes[i];
            var dataCollectionObj = CBTController.parseJSON(tempNode.getAttribute("data-cbt"));
            if (dataCollectionObj && dataCollectionObj.event === "select_item") {
                if (productCollection.indexOf(dataCollectionObj.ecommerce.items.item_id) === -1) {
                    productCollection.push(dataCollectionObj.ecommerce.items.item_id);
                    productCounter += 1;
                }
                dataCollectionObj.ecommerce.items.index = productCounter;
                tempNode.dataset.cbt = JSON.stringify(dataCollectionObj);
            }
        }
    }

    /**
     *  if "link" option enable, set same "data-cbt" to all node childNodes,
     *  to have the event in clickListenerRun
     */

    static handleLinks(node) {
        const allCBTData = node.querySelectorAll("[data-cbt]");

        if (!allCBTData.length) {
            return false;
        }

        // Call updateTilesPosition to avoid querying document another time
        CBTController.updateTilesPosition(allCBTData);

        /**
        * Sets the data-cbt attribute of the given child nodes
        * @param {NodeList} childs - the child nodes to set the attribute on
        * @param {string} data - the data to set the attribute to
        */
        function setChildData(childs, data) {
            for (let i = 0; i < childs.length; i += 1) {
                if (childs[i].nodeType === 1) {
                    if (childs[i].getAttribute("data-cbt")) continue;
                    childs[i].setAttribute("data-cbt", data);
                    if (childs[i].childNodes.length) {
                        setChildData(childs[i].childNodes, data);
                    }
                }
            }
        }

        /**
        * Sets the data-selected attribute of the child nodes of the given element
        * @param {NodeList} childs - the child nodes of the given element
        * @param {string} data - the data to be set
        */
        function setChildDataForRefinementSelectionState(childs, data) {
            for (let i = 0; i < childs.length; i += 1) {
                if (childs[i].nodeType === 1) {
                    if (childs[i].getAttribute("data-selected")) continue;
                    childs[i].setAttribute("data-selected", data);
                    if (childs[i].childNodes.length) {
                        setChildDataForRefinementSelectionState(childs[i].childNodes, data);
                    }
                }
            }
        }

        for (let i = 0; i < allCBTData.length; i += 1) {
            let tempNode = allCBTData[i];
            const dataStr = tempNode.getAttribute("data-cbt");
            const refinementSelectionStateDataStr = tempNode.getAttribute("data-selected");
            const dataObj = CBTController.parseJSON(dataStr);

            if (!dataObj) continue;

            if (typeof dataObj.eventTypes !== "undefined" && dataObj.eventTypes.indexOf("link") !== -1) {
                if (tempNode.childNodes.length) {
                    setChildData(tempNode.childNodes, dataStr);
                    if (refinementSelectionStateDataStr) {
                        setChildDataForRefinementSelectionState(tempNode.childNodes, refinementSelectionStateDataStr);
                    }
                }
            }

            if (typeof dataObj.eventTypes !== "undefined" && dataObj.eventTypes.indexOf("add") !== -1) {
                let dynamicNodes = document.querySelectorAll(dataObj.selector);
                if (dynamicNodes.length) {
                    for (let j = 0; j < dynamicNodes.length; j += 1) {
                        let tempDynamicNode = dynamicNodes[j];
                        // eslint-disable-next-line
                        let dataObj = CBTController.parseJSON(dataStr);
                        let eventTypes = dataObj.eventTypes.split(",");
                        eventTypes.splice(eventTypes.indexOf("add"), 1);
                        dataObj.eventTypes = eventTypes.join(",");
                        tempDynamicNode.setAttribute("data-cbt", JSON.stringify(dataObj));
                    }
                    tempNode.removeAttribute("data-cbt");
                }
            }
        }
    }

    /**
     * run AJAX listener, to find all (cbt_data in JSON) or (data-cbt in HTML) sent via XMLHttpRequest
     */
    static AJAXListenerRun() {
        var listenerSend = XMLHttpRequest.prototype.send;
        XMLHttpRequest.prototype.send = function () {
            var callback = this.onreadystatechange;
            this.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    if (this.getAllResponseHeaders().indexOf("application/json") !== -1) {
                        var myArr = CBTController.parseJSON(this.responseText);
                        if (myArr.cbt_data || myArr.cbt_data_productSet) {
                            if (myArr.cbt_data instanceof Array) {
                                for (var i = 0; i < myArr.cbt_data.length; i += 1) {
                                    var result = myArr.cbt_data[i];
                                    CBTController.dataPush(result);
                                }
                            }
                            CBTController.dataPush(myArr.cbt_data);
                            CBTController.dataPush(myArr.cbt_data_productSet);
                        }
                    }

                    if (this.getAllResponseHeaders().indexOf("text/html") !== -1) {
                        var container = document.implementation.createHTMLDocument("").documentElement;
                        container.innerHTML = this.responseText;
                        var timeout = null;
                        if (timeout) {
                            clearTimeout(timeout);
                        }
                        timeout = setTimeout(function () {
                            CBTController.findAllDataDOM(container);
                        }, 1);
                    }
                }
                if (callback) {
                    callback.apply(this, arguments);
                }
            };
            listenerSend.apply(this, arguments);
        };
    }

    // run DOM updates listener, to find all cbt-data added to DOM
    static DOMListenerRun() {
        var timeout = null;
        let observer = new MutationObserver(function () {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(function () {
                CBTController.handleLinks(document);
            }, 1000);
        });
        const targetNode = document.body;
        const config = { childList: true, subtree: true };
        observer.observe(targetNode, config);
    }

    static clickListenerRun() {
        let allContainers = document.querySelectorAll("body,.container");
        for (let i = 0; i < allContainers.length; i += 1) {
            let tempNode = allContainers[i];
            tempNode.addEventListener("click", function (event) {
                if (event && event.target && event.target.getAttribute("data-cbt")) {
                    let node = event.target;
                    let data = CBTController.parseJSON(node.getAttribute("data-cbt"));
                    if (data && data.eventTypes && data.eventTypes.indexOf("click") !== -1) {
                        if (data.event !== "select_item") {
                            if (!data.action) {
                                data.action = "click";
                            }
                            data.category = "Click";
                        }

                        if (data.event === "share" || data.event === "menu_click" || data.event === "product_filter_plp") {
                            delete data.label;
                            delete data.action;
                            delete data.category;
                            delete data.action;
                        }

                        if (data.event === "form_submit" || data.event === "login" || data.event === "account_activity") {
                            delete data.category;
                            delete data.action;
                        }

                        if (data.event === "product_filter_plp") {
                            window.gridload = true;
                            window.impressionsContainer = [];
                        }

                        if (data.event === "product_filter_plp" && node.getAttribute("data-selected") === "true") {
                            return;
                        }

                        if (data.event === "product_filter_pdp" || data.event === "product_filter_qv") {
                            if (node.classList.contains("selected")) return;
                            delete data.label;
                            delete data.action;
                            delete data.category;
                        }

                        if (liveClick.indexOf(data.event) === -1) {
                            liveClick.push(data.event);
                            setTimeout(function () {
                                liveClick.splice(liveClick.indexOf(data.event), 1);
                            }, 10);
                        } else {
                            return;
                        }
                        CBTController.dataPush(data);
                    }
                }
            });
        }
    }

    static changeListenerRun() {
        document.addEventListener("change", function (event) {
            if (event && event.target && event.target.getAttribute("data-cbt")) {
                let node = event.target;
                let data = CBTController.parseJSON(node.getAttribute("data-cbt"));
                if (data && data.eventTypes && data.eventTypes.indexOf("change") !== -1) {
                    if (node.options) {
                        data.label = node.options[node.options.selectedIndex].text;
                        if (data.event === "product_filter_plp") {
                            window.gridload = true;
                            var ifSorting = data.filter_type === "Sort" ? true : data.filter_type === "Sorting";
                            if (ifSorting) {
                                if (node.options[node.options.selectedIndex].dataset.id) {
                                    var sortingRuleOnChange = (node.options[node.options.selectedIndex].dataset.id).split("-");
                                    data.filter_value = "";
                                    for (var i = 0; i < sortingRuleOnChange.length; i += 1) {
                                        if (sortingRuleOnChange.length - i === 0) {
                                            data.filter_value += sortingRuleOnChange[i];
                                        } else {
                                            data.filter_value += sortingRuleOnChange[i] + " ";
                                        }
                                    }
                                }
                            }
                        }
                    }
                    if (typeof node.attributes.type !== "undefined" && node.attributes.type.nodeValue === "checkbox") {
                        if (!data.label) {
                            data.label = node.checked ? "checked" : "unchecked";
                        }
                    }
                    if (!data.label) {
                        data.label = node.value;
                    }
                    if (!data.action) {
                        data.action = "change";
                    }
                    data.category = "Click";

                    if (data.event === "share" || data.event === "menu_click" || data.event === "product_filter_plp") {
                        delete data.label;
                        delete data.action;
                        delete data.category;
                    }

                    if (data.event === "product_filter_pdp" || data.event === "product_filter_qv") {
                        data.filter_value = data.label;
                        delete data.label;
                        delete data.action;
                        delete data.category;
                    }
                }
                CBTController.dataPush(data);
            }
        });
    }

    static SFRAClick() {
        var cbtData = document.getElementById("cbt-sfra-links").value;
        document.querySelector(".menu-footer.locate-store").dataset.cbt = cbtData;

        var cbtAccountMenus = document.querySelectorAll("#collapsible-account li > a");
        cbtAccountMenus.forEach(function (item) {
            var tempCbt = JSON.parse(cbtData);
            tempCbt.menu_item_name = item.textContent;
            tempCbt.menu_item_section = "Account";
            item.dataset.cbt = JSON.stringify(tempCbt);
        });
        var cbtCustomerServiceMenus = document.querySelectorAll("#collapsible-customer-service li > a");
        cbtCustomerServiceMenus.forEach(function (item) {
            var tempCbt = JSON.parse(cbtData);
            tempCbt.menu_item_name = item.textContent;
            tempCbt.menu_item_section = "Customer Service";
            item.dataset.cbt = JSON.stringify(tempCbt);
        });
        var cbtAboutMenus = document.querySelectorAll("#collapsible-about li > a");
        cbtAboutMenus.forEach(function (item) {
            var tempCbt = JSON.parse(cbtData);
            tempCbt.menu_item_name = item.textContent;
            tempCbt.menu_item_section = "About";
            item.dataset.cbt = JSON.stringify(tempCbt);
        });
    }

    static SFRABaseFixes() {
        var eventCleaner = setInterval(() => {
            $(".container").off("change", "[name=sort-order]");
            var foundSelect = false;
            var foundSelectColor = false;
            $._data(document).events.change.forEach((event) => {
                if (event.selector === 'select[class*=\"select-\"], .options-select') { // eslint-disable-line
                    if (foundSelect) {
                        event.handler = () => {};
                    } else {
                        foundSelect = true;
                    }
                }
            });
            $._data(document).events.click.forEach((event) => {
                if (event.selector === "[data-attr=\"color\"] button") {
                    if (foundSelectColor) {
                        event.handler = () => {};
                    } else {
                        foundSelectColor = true;
                    }
                }
            });
        }, 1000);
        setTimeout(() => {
            clearInterval(eventCleaner);
        }, 10000);
        /* eslint-disable */
        $(document).on('change', '[name=sort-order]', (function (t) {
            t.preventDefault(),
            $.spinner().start();
            var selectedUrl = this.value;
            var url = new URL(selectedUrl);
            var queryParamValue = url.searchParams.get('srule');
            // Remove the srule parameter from the base URL
            var baseUrl = new URL(this.baseURI);
            baseUrl.searchParams.delete('srule');
            // Construct the new URL with the updated srule parameter
            baseUrl.searchParams.set('srule', queryParamValue);
            // Update the URL without reloading the page
            history.pushState(null, null, baseUrl.toString());
            $(this).trigger('search:sort', this.value),
            $.ajax({
                url: this.value,
                data: {
                    selectedUrl: this.value
                },
                method: 'GET',
                success: function (t) {
                    $('.product-grid').empty().html(t),
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
        ));
        /* eslint-enable */
    }
}

/**
 * initial cbt-data search and dataLayer push on page
 * AJAX listener run
 */

document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementById("cbt-sfra-links")) {
        CBTController.SFRAClick();
    }
    CBTController.findAllDataDOM(document, true);
    CBTController.AJAXListenerRun();
    CBTController.DOMListenerRun();
    CBTController.SFRABaseFixes();

    if (cbtSitePreferences.CBT_CLICK) {
        CBTController.clickListenerRun();
        CBTController.changeListenerRun();
    }
});
