"use strict";

const htmlUnescapes = {
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&quot;": "\"",
    "&#39;": "'"
};

const reEscapedHtml = /&(?:amp|lt|gt|quot|#(0+)?39);/g;
const reHasEscapedHtml = RegExp(reEscapedHtml.source);

/**
 * Unescapes a given string by replacing HTML entities with their corresponding characters
 * @param {string} string - the string to unescape
 * @returns {string} the unescaped string
 */
function unescape(string) {
    return string && reHasEscapedHtml.test(string)
        ? string.replace(
            reEscapedHtml,
            (entity) => htmlUnescapes[entity] || "'"
        )
        : string || "";
}

module.exports = unescape;
